import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApNotificationService {


    list_user_group() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_admin_user/list_user_group", form);

    }
    
    view_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_admin_user/view_user_group", form);

    }

    insert_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_admin_user/insert_user_group", form);

    }

    update_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_admin_user/update_user_group", form);

    }    


    list_user() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_admin_user/list_user", form);

    }
    
    view_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_admin_user/view_user", form);

    }

    insert_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_admin_user/insert_user", form);

    }

    update_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_admin_user/update_user", form);

    }    


}

    
 

export default new ApNotificationService();